import React from 'react'
import ReactDOM from 'react-dom';

import './style.css';

class Interstitial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companies: [
        {
          company: 'Strype & Paypal',
          description: 'To process credit cards and offer secure payments we integrate these services. If you pay us through these services, these companies may collect further data about you according to their privacy policies. '
        }
      ]
    }
      // this.wrapperRef = React.createRef();
      // this.setWrapperRef = this.setWrapperRef.bind(this);
      // this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside = (event) => {
    // if(this.node.contains(event.target)) {
    //   alert('You clicked inside of me!');
    // }
      // if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      //     alert('You clicked outside of me!');
      // }
  }

  closeModal = () => {
    document.querySelector('#interstitial').classList.add('hidden')
  }

  goToPayment = () => {
    fetch('/evnts/e?ec=click&ea=ok_interstitial2')
    const goToPaymentLink = document.getElementById('get-disconnect');
    window.location.href = goToPaymentLink
  }

  render() {
    return (
      <div className="interstitial-bg">
          <div className="interstitial-box">
          <div className="interstitial-content pb4">
            <h3>Privacy notice - product page</h3>
            <p>To prevent fraud and offer secure payments on the page you're trying to visit our payment processors listed below may collect data about your device. Other than the product page, Disconnect doesn't allow companies to collect any data from our website visitors or products.</p>
            
            <ul className="list-reset mb4">
              <li className="mb4 mt4">
                <div className="icon">
                  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M14 3H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z"/>
                    <path d="M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1zM1 9h14v2H1V9z"/>
                  </svg>
                </div>
                <div className="mb3">
                  <span className="title"><a href="https://stripe.com/privacy" target="_blank">Stripe</a></span>
                </div>
              </li>
            </ul>
            <hr className="mt4"/>
          </div>
          <div className="interstitial-buttons">
              <button onClick={this.closeModal}>Go back</button>
              <button className="green" onClick={this.goToPayment}>Ok</button>
          </div>
        </div>
      </div>
    )

  }
}


ReactDOM.render(
  <Interstitial />, 
  document.querySelector("#interstitial")
  
)